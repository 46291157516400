import React, { useRef, useEffect } from 'react';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import Img1 from "../../../../assets/gallery/img1.png";
import Img2 from "../../../../assets/gallery/img2.png";
import Img3 from "../../../../assets/gallery/img3.png";
import Img4 from "../../../../assets/gallery/img4.png";
// Stylesheet
import "./gallerycollapse.scss";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}


const GalleryCollapse = () => {
  return (
    
    <section className="gallerycollapse" id="Gallery">

<Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >

      <LayoutMain>
        <div className="gallary_title">
          <div className="psudo-border">
            <p>GALLERY</p>
          </div>
          <h2>Divum</h2>
          <p>Embark on a visual journey through the meticulous craftsmanship, architectural marvels, and exquisite details that define our creations.</p>
        </div>
      </LayoutMain>
      <Layout>
        <div className="gallary_container">
          <div className="gallary_item grid_row_span_2">
            <div class="card">
            <a data-fancybox="gallery" href={Img1}>
            <img src={Img1} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVUMM</span>
            </div>
            </a>
            </div>
          </div>
          <div className="gallary_item ">
            <div class="card">
            <a data-fancybox="gallery" href={Img2}>
            <img src={Img2} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVUM</span>
            </div>
            </a>
            </div>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
            <div class="card">
            <a data-fancybox="gallery" href={Img3}>
            <img src={Img3} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVU</span>
            </div>
            </a>
            </div>
          </div>
          <div className="gallary_item">
            <div class="card">
            <a data-fancybox="gallery" href={Img4}>
            <img src={Img4} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVUM</span>
            </div>
            </a>
            </div>
          </div>
        </div>
        <div className="gallary_container">
        <div className="gallary_item grid_row_span_2">
            <div class="card">
            <a data-fancybox="gallery" href={Img1}>
            <img src={Img1} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVU</span>
            </div>
            </a>
            </div>
          </div>
          <div className="gallary_item ">
            <div class="card">
            <a data-fancybox="gallery" href={Img2}>
            <img src={Img2} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVUMM</span>
            </div>
            </a>
            </div>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
            <div class="card">
            <a data-fancybox="gallery" href={Img3}>
            <img src={Img3} alt="gallery img" loading="lazy" />
            <div class="overlay">
              <span className="hover-text">DIVUM</span>
            </div>
            </a>
            </div>
          </div>
          <div className="gallary_item">
            <div class="card">
            <a data-fancybox="gallery" href={Img4}>
            <img src={Img4} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">DIVUM</span>
            </div>
            </a>
            </div>
          </div>
        </div>
      </Layout>
      </Fancybox>
    </section>

  );
};


export default GalleryCollapse;
