import { Link } from 'react-router-dom';
import LogoLight from '../../../../assets/logo-light-new.svg';
import { dashboardNavLinks } from '../../../../constants/dashboardNavLinks';
import SidebarItem from './SidebarItem';
import './adminSidebar.scss';

const AdminSidebar = () => {
  return (
    <div className="admin_sidebar">
      <Link to="/admin" className="sidebar_container">
        <img src={LogoLight} alt="main logo" loading="lazy" />
      </Link>
      <div className="sidebar_links">
        {dashboardNavLinks.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </div>
    </div>
  );
};

export default AdminSidebar;
