import React from 'react'
import { format } from 'date-fns'

import share from '../../../../assets/blogdetail/share.svg'
import SocialShareDropdown from '../../../SocialShareDropdown';

import './toparticle.scss';

const TopArticle = ({ title, date }) => {
  return (

    <section className='toparticleblogdetail'>

      <div className="top-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h6>Article</h6>
              <span className="psudo-border"></span>
            </div>
            <h2>{title}</h2>
          </div>
          <div className='share-link'>
            <span>{format(new Date(date), 'dd MMM, yyyy')} <small>|</small> <a href='link'><img src={share} alt='blogdetail' loading="lazy"/> SHARE</a></span>

            <SocialShareDropdown />

          </div>
        </div>
      </div>
    </section>





  )
}

export default TopArticle
