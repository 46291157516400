import React, { useRef, useEffect } from 'react';
import Layout from "../../../layout/Layout/Layout";

// Images
import PlayIcn from '../../../../assets/icons/play.png'
// Stylesheet
import './walkthroughluma.scss'

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}

const Walkthrough = () => {
  return (
    <Fancybox
    options={{
      Carousel: {
        infinite: false,
      },
    }}
  >
    <Layout>
    <section className='walkthroughluma overlay-bg' id='Walkthrough'>
      <div className='walkthrough_container'>
        <p>WALKTHROUGH</p>
        <h2>EXPLORE LUMA <br/>THROUGH A VIDEO TOUR</h2>
        <div className='mt-2'>
        <a data-fancybox="gallery" href={"https://www.youtube.com/watch?v=iFGdFJis_ec"}>
            <img src={PlayIcn} alt="play" loading="lazy"/>
            </a>


      </div>
      </div>
    </section>
    </Layout>
    </Fancybox>
  )
}

export default Walkthrough
