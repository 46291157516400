import React, { useState } from 'react';
import PlusWhite from '../../../../assets/icons/Plus-white.svg';
import { Link } from 'react-router-dom';

const SidebarItem = ({ item }) => {
  const [active, setActive] = useState(true);
  if (item.childrens) {
    return (
      <div className="sidebar_item">
        <div className="sidebar_item_container">
          <div className="sidebar_title">
            <div className="mark_bullet"></div>
            <p>{item.link}</p>
          </div>
          <img onClick={() => setActive(!active)} src={PlusWhite} alt="white_logo" loading="eager" />
        </div>
        <div className="reveal_sections">
          {active && (
            <>
              {item.childrens.map((child, index) => (
                <Link to={child.path} key={index}>
                  <p>{child.link}</p>
                </Link>
              ))}
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="sidebar_item">
        <div className="sidebar_item_container">
          <div className="sidebar_title">
            <div className="mark_bullet"></div>
            <Link to={item.path}>
              <p>{item.link}</p>
            </Link>
          </div>
        </div>
      </div>
    );
  }
};

export default SidebarItem;
