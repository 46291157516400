import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';

import Layout from '../../../layout/Layout/Layout';

import './cardlisting.scss';

const renderBlogList = (blog) => {

  if(blog.post_status != 'publish') {
    return false;
  }
  const titleForUrl = blog.post_title.toLowerCase().replace(/ /g,"-");
    return (
      <Link to={`/blogs/${encodeURIComponent(blog.post_slug)}`}>
      <figure>
        <h3>{blog.post_title}</h3>
        <figcaption>
          <div className="overlayimage">
            <img src={blog.post_meta_thumbnail} alt={blog.post_title} loading="lazy"/>
          </div>
        </figcaption>
        <div className="date-list">
          <span>{format(new Date(blog.post_publish_date), 'dd MMM, yyyy')}</span>
        </div>
      </figure>
    </Link>
  );
};

const CardListing = ({ blogList }) => {
  const [currentPage, setcurrentPage] = useState(1);
  const itemsPerPage = 12;

  return (
    <section className="blogcards">
      <Layout>
        <div className="bloglisting">
          <ul>
            {blogList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((datum) => (
              datum.post_status == 'publish' ? (
                <li key={datum.id}>{renderBlogList(datum)}</li>
              ) : (
                ''
              )  
             
            ))}
          </ul>
        </div>
      </Layout>
      {CardPagination(currentPage, setcurrentPage, blogList, itemsPerPage)}
    </section>
  );
};

const CardPagination = (currentPage, setcurrentPage, blogList, itemsPerPage) => {
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const pageNumberLimit = 5;
  const pages = [];
  let pageIncrementBtn = null;
  let pageDecrementBtn = null;

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  for (let i = 1; i <= Math.ceil(blogList.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li key={number} id={number} onClick={handleClick} className={currentPage == number ? 'active' : null}>
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = <li onClick={handleNextbtn}> &hellip; </li>;
  }

  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  return (
    <>
      {blogList && blogList.length > 0 && (
        <ul className="pageNumbers">
          <li>
            <button onClick={handlePrevbtn} disabled={currentPage == pages[0] ? true : false}>
              &lt;
            </button>
          </li>
          {pageDecrementBtn}
          {renderPageNumbers}
          {pageIncrementBtn}

          <li>
            <button onClick={handleNextbtn} disabled={currentPage == pages[pages.length - 1] ? true : false}>
              &gt;
            </button>
          </li>
        </ul>
      )}
    </>
  );
};

export default CardListing;
