import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/csrbanner.png";
// Stylesheet
import "./csrpage.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='csrpg' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                <h2 className="hidedesk">OUR SOCIAL INITIATIVES</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div className="content-one">
                <h2 className="hidemob">OUR SOCIAL INITIATIVES</h2>

                    <h4>GOENKA & ASSOCIATE EDUCATIONAL TRUST (G.A.E.T.)</h4>

                    <p>Established for educating under-privileged children.</p>

                    <h4>GOENKA & ASSOCIATES MEDICAL & RESEARCH CENTRE</h4>

                    <p> Established for providing medical attention who face restriction due to limited resources.</p>

                    <h4>GOENKA & ASSOCIATES SOCIAL WELFARE TRUST</h4>

                    <p>Established for promoting, establishing, maintaining or assisting social & cultural activities and health & welfare of the community.</p>
                    <p>Under this trust, ‘Krishna Vatika Devasthan’ was set-up in Goregoan East so that people dedicate more time to spiritual happiness and find inner peace. This Temple complex in Golkuldham provides ample area for people to gather, pray, meditate, and engage in leisurely discourse and functions.</p>
                </div>
                
            </section>
            
        </Layout>
        



    )
}

export default Landmark;
