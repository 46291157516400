import React, { useRef, useEffect } from "react";
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import GG1rep from "../../../../assets/gallery/GG1rep.webp";
import GG2rep from "../../../../assets/gallery/GG2rep.webp";
import GG3rep from "../../../../assets/gallery/GG3rep.webp";
import GG4rep from "../../../../assets/gallery/GG4rep.webp";
import GG5rep from "../../../../assets/gallery/GG5rep.webp";
import GG6rep from "../../../../assets/gallery/GG6rep.webp";
import GG7rep from "../../../../assets/gallery/GG7rep.webp";
import GG8rep from "../../../../assets/gallery/GG8rep.webp";



// Stylesheet
import "./gallerygoa.scss";

import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}
//
const Collage = () => {
  return (

    <section className="gallerycollapsealdeiadegoa">
       <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >
      <>
        <div className="gallary_containeraldeiadegoa">
        <div className="gallary_item grid_row_span_2">
            <a data-fancybox="gallery" href={GG1rep}>
              <img src={GG1rep} alt="gallery img" loading="lazy"/>
              <div class="overlay">
                <span className="hover-text">VILLA SIDE ANGLE</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG2rep}>
              <img src={GG2rep} alt="gallery img" loading="lazy" />
              <div class="overlay">
                <span className="hover-text">SEATING AREA</span>
              </div>
            </a>
          </div>
          <div className="gallary_item grid_col_span_2 grid_row_span_2">
          <a data-fancybox="gallery" href={GG3rep}>
              <img src={GG3rep} alt="gallery img" loading="lazy" />
              <div class="overlay">
                <span className="hover-text">LIVING ROOM</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG4rep}>
              <img src={GG4rep} alt="gallery img" loading="lazy"/>
              <div class="overlay">
                <span className="hover-text">KITCHEN</span>
              </div>
            </a>
          </div>

          <div className="gallary_item grid_row_span_2">
          <a data-fancybox="gallery" href={GG5rep}>
              <img src={GG5rep} alt="gallery img" loading="lazy"/>
              <div class="overlay">
                <span className="hover-text">SWIMMING POOL</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG6rep}>
              <img src={GG6rep} alt="gallery img" loading="lazy" />
              <div class="overlay">
                <span className="hover-text">GARDEN AREA</span>
              </div>
            </a>
          </div>

          <div className="gallary_item grid_col_span_2 grid_row_span_2">
          <a data-fancybox="gallery" href={GG7rep}>
              <img src={GG7rep} alt="gallery img" loading="lazy"/>
              <div class="overlay">
                <span className="hover-text">DINNING ROOM</span>
              </div>
            </a>
          </div>
          <div className="gallary_item">
          <a data-fancybox="gallery" href={GG8rep}>
              <img src={GG8rep} alt="gallery img" loading="lazy" />
              <div class="overlay">
                <span className="hover-text">MASTER BEDROOM</span>
              </div>
            </a>
          </div>

        </div>
      </>
      </Fancybox>
    </section>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE ALDEIA DE GOA</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />
        {/* Single collage */}

      </Layout>
    </section>

  );
};


export default Gallery;
