import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import QRavanya1 from '../../../../assets/pdp/avanyaqr/Avanya-Tower-A-RERA-QR-Code.jpg'
import QRavanya2 from '../../../../assets/pdp/avanyaqr/Avanya-Tower-B-RERA-QR-Code.jpg'
import QRavanya3 from '../../../../assets/pdp/avanyaqr/Avanya-Tower-C-RERA-QR-Code.jpg'

// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
       <div className='pdp_decription'>
                <div className='bannertagline'>
                    <p>MahaRERA Registration Numbers P51800003202, P51800026590 & P51800033462. <br/> Project details are available at
                    <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener"> https://maharera.mahaonline.gov.in</a></p>
                </div>

                <div className='pdp_barcode'>
                    <div className='barcode'>
                        <img src={QRavanya1} alt="plan" loading="lazy"/>
                        <span>Avanya Tower A</span>
                    </div>
                    <div className='barcode'>
                        <img src={QRavanya2} alt="plan" loading="lazy"/>
                        <span>Avanya Tower B</span>
                    </div>
                    <div className='barcode'>
                        <img src={QRavanya3} alt="plan" loading="lazy"/>
                        <span>Avanya Tower C</span>
                    </div>
                </div>
            </div>
    </Layout>

    </section>

  );
};

export default Barcode;
