import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Parkwoods-Overview.webp";
// Stylesheet
import "./landmarkparkwood.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='grouppagecompany' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                <h2>Education</h2>

                    <p>GIVING YOUNG MINDS THE WINGS TO FLY.</p>

                    <p>We at Dynamix immensely value the power of education and the need for nurturing minds. After all, our future lies in their hands. The Group, through its various CSR initiatives and foundation lends support this extremely important and meaningful aspect of social development.</p>

                    <p> With the primary focus to give every child the equality of opportunity, The Goenka & Associates Educational Trust (G.A.E.T.) was established to impart the best education to students through its schools and educational institutes.</p>

                    <p>G.A.E.T has established 117 excellent vehicles towards fulfilling this mission and currently educates over 22000+ students.</p>

                    <p><a href="https://gaet.edu.in/gaet/index.jsp" target="_blank" rel="noreferrer noopener" aria-label="GOENKA &amp; ASSOCIATES EDUCATIONAL TRUST (opens in a new tab)">GOENKA &amp; ASSOCIATES EDUCATIONAL TRUST</a></p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
