import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// Components
import Layout from '../../layout/Layout/Layout';
import SideBar from './SideBar';
import BottomNav from './BottomNav';
// Images
import LogoLight from '../../../assets/logo-light-new.svg';
import LogoDark from '../../../assets/logo-dark-new.svg';
import SearchLight from '../../../assets/icons/search-light.svg';
import SearchDark from '../../../assets/icons/search-dark.svg';
import MenuLight from '../../../assets/icons/menu-light.svg';
import MenuDark from '../../../assets/icons/menu-dark.svg';
// Stylesheet
import './navigation.scss';
import MegaMenu from './megaMenu/MegaMenu';

const Navigation = () => {
  const [activeNav, setActiveNav] = useState(false);
  const [megaMenu, setMegaMenu] = useState(false);
  const [color, setColor] = useState(false);
  const location = useLocation();

  useEffect(() => {
    chengeColor();
  }, [location.pathname]);

  const chengeColor = () => {
    if (
      location.pathname === '/current-projects' ||
      location.pathname === '/completed-projects' ||
      location.pathname === '/about-us' ||
      location.pathname === '/careers' ||
      location.pathname === '/buyers-guide' ||
      location.pathname === '/gallerypage' ||
      location.pathname === '/our-legacy' ||
      location.pathname.split('/')[1] === 'blogs' ||
      location.pathname === '/contact-us' ||
      location.pathname === '/disclaimer' ||
      location.pathname === '/privacy-policy' ||
      location.pathname === '/terms-conditions' ||
      location.pathname === '/channel-partner' ||
      location.pathname === '/education' ||
      location.pathname === '/dairy' ||
      location.pathname === '/healthcare' ||
      location.pathname === '/corporate-social-responsiblity' ||
      location.pathname === '/lumatower-c' ||
      location.pathname === '/nri-desk' 
    ) {
      setColor(true);
    } else if (window.scrollY >= 800) {
      if (!color) setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener('scroll', chengeColor);
  return (
    <>
      <header>
        <Layout>
          <nav className={color ? 'sticky_nav' : ''}>
            <Link to="/" className="nav_logo">
              {color ? <img src={LogoDark} alt="logo" loading="lazy"/> : <img src={LogoLight} alt="logo" loading="lazy"/>}
            </Link>
            <div className="nav_links">
              <p className="nav_btn"><Link to={"/contact-us"}>GET IN TOUCH</Link></p>
              {/* {color ? (
                <img onClick={() => setActiveNav(!activeNav)} src={SearchDark} alt="search" />
              ) : (
                <img onClick={() => setActiveNav(!activeNav)} src={SearchLight} alt="search" />
              )} */}
              {color ? (
                <img src={MenuDark} alt="menu" onClick={() => setMegaMenu(!megaMenu)} loading="lazy" />
              ) : (
                <img src={MenuLight} onClick={() => setMegaMenu(!megaMenu)} alt="menu" loading="lazy" />
              )}
            </div>
          </nav>
        </Layout>
      </header>
      {/* Sidebar */}
      <SideBar activeNav={activeNav} setActiveNav={setActiveNav} />
      {/* Bottom nav */}
      <BottomNav />
      {/* Mega menu */}
      <MegaMenu megaMenu={megaMenu} setMegaMenu={setMegaMenu} />
    </>
  );
};

export default Navigation;
