import React, { useRef } from 'react'
// Component
import ButtonPrimary from '../../button/ButtonPrimary';
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import { format } from 'date-fns';

// Images
import SliderBtn from '../../../../assets/icons/cursor-arrow.svg'
import SliderBtnleft from '../../../../assets/icons/cursor-arrowleft.svg';


const BlogSlider = ({blogData}) => {
   
    const swiperRef = useRef();
    return (
        <div className='blog_slider'>
            <img className="slider_btn leftbtn" onClick={() => swiperRef.current.slidePrev()} src={SliderBtnleft} alt="Slider Btn" loading="lazy"/>
            <img className='slider_btn' onClick={() => swiperRef.current.slideNext()} src={SliderBtn} alt="Slider Btn" loading="lazy"/>
            <Swiper
                spaceBetween={5}
                slidesPerView={2.5}
                // mousewheel={true}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1.2,
                        spaceBetween: 20,
                    },
                    '@0.75': {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 2,
                        spaceBetween: 20,
                    },
                    '@1.50': {
                        slidesPerView: 2.5,
                        spaceBetween: 20,
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                      },
                }}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                }}
                modules={[Mousewheel]}
            >
                {
                    blogData?.map((item, indx) => (
                        <SwiperSlide key={indx}>
                            <a href={`/blogs/${item.post_slug}`}>
                                <div className='blog_item'>
                                    <div>
                                        <img src={item.post_meta_thumbnail} alt="log img" loading="lazy"/>
                                        <p className='blog_date'>{format(new Date(item.post_publish_date), 'dd MMM, yyyy')}</p>
                                    </div>
                                    <p className='blog_title'>{item.post_title}</p>
                                </div>
                            </a>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
}


export default BlogSlider
