import React from 'react'

import detailimage1 from '../../../../assets/blogdetail/detailimage1.webp'
import detailimage2 from '../../../../assets/blogdetail/detailimage2.webp'
import Layout from "../../../layout/Layout/Layout";

import './blogscontent.scss';

const BlogsContent = ({ blog }) => {
  
  return (

    <section className='zigzagsection'>

      <Layout>
        <div className='blogsrow'>
          <div className='imgblog'>
            <img src={blog.post_meta_thumbnail} alt='detailimage2' loading="lazy"/>
          </div>
          <div className='txtblog' dangerouslySetInnerHTML={{__html: blog.post_content}} />
        </div>
      </Layout>


    </section >





  )
}

export default BlogsContent
