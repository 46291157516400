import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/dairybanner.jpg";
// Stylesheet
import "./dairypg.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='dairypg' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                <h2 className="hidedesk">We Own India’s Finest Dairy.</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                <h2 className="hidemob">We Own India’s Finest Dairy.</h2>



                    <p>The visionary Chairman of the Dynamix Group, the Late Mr. K.M. Goenka, founded the company on the core principles of producing high-quality milk products from the best cow milk, in line with world class technology and infrastructure. Dynamix Dairies Limited commissioned its first dairy in Baramati in the late ‘90s. In 2004, USA based internationally renowned dairy giant, Schreiber Foods Inc acquired a majority stake in Dynamix Dairies Limited to form a leading dairy product company Schreiber Dynamix Dairies Limited.</p>

                    <p> India’s finest dairy, it processes over a million liters of cow’s milk a day and is associated with renowned companies like Nestle, Britania, Danone & PepsiCo to produce and package variety of dairy products, as well as other edibles.</p>

                    <p>Schreiber Dynamix Dairies Ltd. has been successfully exporting to various countries all over the world, including Algeria, Bahrain, Bangladesh, Canada, China, Egypt, Iran, Korea, Kuwait, Lebanon, Malaysia, Morocco, Oman, Philippines, Qatar, Saudi Arabia, Singapore, Sri Lanka, Syria, Turkey, UAE and USA.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
