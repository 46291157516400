import React, { useRef } from 'react';
// Component
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

// Images

// SwiperCore.use([Keyboard, Mousewheel]);

const JoincareerSlider = ({ expData }) => {
  const swiperRef = useRef();
  return (
    <div className="exp_slider_container">
      <Swiper
        spaceBetween={0}
        slidesPerView={2}
        // mousewheel={true}
        breakpoints={{
          '@0.00': {
            slidesPerView: 1.2,
            spaceBetween: 10
          },
          '@0.75': {
            slidesPerView: 1.2,
            spaceBetween: 10
          },
          '@1.00': {
            slidesPerView: 1.1,
            spaceBetween: 10
          },
          '@1.50': {
            slidesPerView: 1.1,
            spaceBetween: 10
          }
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Mousewheel]}
      >
        {expData?.map((item, indx) => (
          <SwiperSlide key={indx}>
            <div className="sliderbox">
              <div className="exp_item">
                <img src={item.src} alt="slider img" loading="lazy"/>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default JoincareerSlider;
