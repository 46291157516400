import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Parkwoods-Overview.webp";
// Stylesheet
import "./landmarkparkwood.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkparkwood' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>Your Sanctuary</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                    <h4>Located off Ghodbunder Road, Parkwoods promises excellent connectivity, including the proposed metro
                        station a mere 100 metres away. It is surrounded by open greens, as well as planned social infrastructure, including a DMart and a proposed school to be built on the larger layout of the property amongst numerous additions to enhance your comfort and convenience further. Our spacious homes take you and your family’s lifestyle to the next level. Surrounded by plush amenities and spacious interiors,  ushering into the world of tomorrow</h4>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
