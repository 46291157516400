import React, { useState } from "react";
import Layout from '../../../layout/Layout/Layout';
import Facebook from '../../../../assets/icons/facebook.png';
import Instagram from '../../../../assets/icons/instagram.png';
import LinkedIn from '../../../../assets/icons/linkedin.png';
import Twitter from '../../../../assets/icons/twitter.png';
import Featimage from '../../../../assets/raet-menu.webp';
import plus from '../../../../assets/icons/plus.svg';
import MenuBtn from '../../../../assets/icons/menu-button.png';

import { Link } from 'react-router-dom';

import './megaMenu.scss';




const MegaMenu = ({ megaMenu, setMegaMenu }) => {
  const closeMegaMenu = () => {
    setMegaMenu(false)
  }
  const toggle = (e) => {
    const current = e.target.getAttribute('data-menu_id');

    let dropmenus = document.querySelectorAll('.menu_linking ul')
    dropmenus.forEach(dropmenu => {
      dropmenu.classList.remove('submenushow');
    });
    document.querySelector('.'+current + 'show').classList.add('submenushow');



  };

  return (
    <>
      <div className={megaMenu ? 'megamenu megamenu_active' : 'megamenu'}>
      {/* <span>Menu</span> */}
        <div className="close_menu_btn" onClick={() => setMegaMenu(!megaMenu)}>
          <img src={MenuBtn} alt="menu_btn" loading="lazy"/> 
        </div>
        <Layout>
          <div className="menurow">
            <div className="menuleft">
              <div className="psudo-box">
                <p>featured project</p>
                <span className="psudo-border"></span>
              </div>
              <div className="feat-img">
                <Link to={"/current-projects/raet"} onClick={closeMegaMenu}>
                  <div className="realtive-box">
                  <img src={Featimage} alt="featimage" loading="lazy"/>
                  <strong>Raet <small>View Details<img src={plus} alt="menu_btn" loading="lazy"/></small></strong>
                  </div>
                </Link>
              </div>
            </div>
            <div className="menuright">
              <div className="menu_linking">
                <ul>
                  <div className="psudo-box">
                    <p><Link to={"/current-projects"} onClick={closeMegaMenu}>Our Projects</Link></p>
                    <span className="psudo-border"></span>
                  </div>
                  <li><Link to={"/current-projects"} onClick={closeMegaMenu}>CURRENT PROJECTS</Link></li>
                  <li><Link to={"/completed-projects"} onClick={closeMegaMenu}>completed Projects</Link></li>
                </ul>
                <hr className="bordermobile" />
                <ul className="aboutusshow">
                  <div className="psudo-box">
                    <p><Link to={"/about-us"} onClick={closeMegaMenu}>About us</Link></p>
                    <small className='btn-click' onClick={toggle} data-menu_id="aboutus"></small> 
                    <span className="psudo-border"></span>
                  </div>
                  <div className='mobilehidemenu'>
                    <li><Link to={"/about-us#Ourstory"} onClick={closeMegaMenu}> Our Story </Link></li>
                    <li><Link to={"/about-us#Ourdesignethos"} onClick={closeMegaMenu}> Our Design Ethos</Link></li>
                    <li><Link to={"/about-us#groupofcompany"} onClick={closeMegaMenu}> Group of Companies</Link></li>
                    <li><Link to={"/about-us#management"} onClick={closeMegaMenu}> Management</Link></li>
                    <li><Link to={"/about-us#cSR"} onClick={closeMegaMenu}> CSR</Link></li>
                  </div>
                </ul>
                <hr className="bordermobile" />
                <ul className="buyersguideshow">
                  <div className="psudo-box">
                    <p><Link to={"/buyers-guide"} onClick={closeMegaMenu}>Buyers Guide</Link></p>
                    <small className='btn-click' data-menu_id="buyersguide" onClick={toggle} ></small>
                    <span className="psudo-border"></span>
                  </div>
                  <div className='mobilehidemenu'>
                    <li><Link to={"/buyers-guide#LOANFAQS"} onClick={closeMegaMenu}>Loan FAQs</Link></li>
                    {/* <li><Link to={"/buyers-guide#TAXBENEFITINFORMATION"} onClick={closeMegaMenu}>Tax Benefits</Link></li> */}
                    <li><Link to={"/buyers-guide#FAQS"} onClick={closeMegaMenu}>FAQs</Link></li>
                    <li><Link to={"/buyers-guide#EMICALCULATOR"} onClick={closeMegaMenu}>EMI Calculator</Link></li>
                    <li><Link to={"/nri-desk"} onClick={closeMegaMenu}>NRI Guide</Link></li>
                  </div>
                </ul>
                <hr className="bordermobile" />
                <ul className="investorsshow">
                  <div className="psudo-box">
                    <p><Link to={"/an-investors-heaven"} onClick={closeMegaMenu}>Investors</Link></p>
                    <small className='btn-click' onClick={toggle} data-menu_id="investors"></small>
                    <span className="psudo-border"></span>
                  </div>
                  <div className='mobilehidemenu'>
                    <li><Link to={"/an-investors-heaven#Annualfiling"} onClick={closeMegaMenu}>Annual Filing</Link></li>
                    <li><Link to={"/an-investors-heaven#Noticescirculars"} onClick={closeMegaMenu}>Notices & Circulars</Link></li>
                  </div>
                </ul>
                <hr className="bordermobile" />
                <ul className="othersshow">
                  <div className="psudo-box">
                    <p>Others</p>
                    <small className='btn-click'  onClick={toggle} data-menu_id="others"></small>
                    <span className="psudo-border"></span>
                  </div>
                  <div className='mobilehidemenu'>
                    <li><Link to={"/careers"} onClick={closeMegaMenu}>Careers</Link></li>
                    <li><Link to={"/blogs"} onClick={closeMegaMenu}>Blogs</Link></li>
                    {/* <li><Link to={"/resources"} onClick={closeMegaMenu}>Resources</Link></li> */}
                    {/* <li><Link to={"/gallerylistingpage"} onClick={closeMegaMenu}>Construction</Link></li> */}
                  </div>
                </ul>
              </div>

              <div className="megabottomtag">
                <div className="rgs-btn">
                  <p>REGISTER TO BECOME A CHANNEL PARTNER</p>
                  <button class="btn_primary"><Link to={"/channel-partner"} onClick={closeMegaMenu}>Register</Link></button>
                </div>
                <div className="footer_logo">
                  <span>Follow us on</span>
                  <div className="social_tag">
                    <Link target="_blank" to="https://www.facebook.com/thedynamixgroup/"><img src={Facebook} alt="social links" loading="lazy"/></Link>
                    <Link target="_blank" to="https://www.instagram.com/dynamixgroup/"><img src={Instagram} alt="social links" loading="lazy"/></Link>
                    <Link target="_blank" to="https://www.linkedin.com/company/dynamixgroup/"><img src={LinkedIn} alt="social links" loading="lazy"/></Link>
                    <Link target="_blank" to="https://twitter.com/dynamix_group"><img src={Twitter} alt="social links" loading="lazy"/></Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Layout>


      </div>
    </>
  );
};

export default MegaMenu;
