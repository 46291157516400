import React from 'react'
import { format } from 'date-fns'

import facebook from '../../../../assets/blogdetail/facebook.svg'
import linkdin from '../../../../assets/blogdetail/linkdin.svg'
import twitter from '../../../../assets/blogdetail/twitter.svg'
import link from '../../../../assets/blogdetail/link.svg'
import config from '../../../../config';
import Layout from "../../../layout/Layout/Layout";
import './blogtags.scss';

const BlogTags = ({ blog }) => {

  const handleShare = (platform) => {
    let url = window.location.href;
    let shareUrl = '';
    switch (platform) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case 'twitter':
        shareUrl = `https://twitter.com/share?url=${url}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/shareArticle?url=${url}`;
        break;
      default:
        break;
    }
    window.open(shareUrl, '_blank');
  };


  return (

    <section className='foottags'>
      <div className='bordertop'></div>

      <Layout>
        <div className="left-tags">
          <ul>
            <li><span>Author</span><span>{blog.post_author}</span></li>
            <li><span>Date</span><span>{format(new Date(blog.post_publish_date), 'dd MMM, yyyy')}</span></li>
            <li><span>Share</span>
              <span>
                <a href='' onClick={() => handleShare('facebook')} ><img src={facebook} alt='facebook' loading="lazy"/></a>
                <a href='link' onClick={() => handleShare('linkdin')}><img src={linkdin} alt='linkdin' loading="lazy"/></a>
                <a href='link' onClick={() => handleShare('twitter')} ><img src={twitter} alt='twitter' loading="lazy"/></a>
                {/* <a href='link'><img src={link} alt='link' /></a> */}
              </span>
            </li>
          </ul>

        </div>
        <div className="right-tags">
          {
            blog.post_tag.length ? (
              <ul>
                <li><span>TAGS</span></li>
                <li>
                  {blog.post_tag.map((datum) => (
                    <a href={'/tags/' + datum.slug}>{datum.name}</a>
                  ))}
                </li>
              </ul>
            ) :
              (
                ''
              )
          }
        </div>
      </Layout>

    </section >





  )
}

export default BlogTags
