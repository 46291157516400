import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/101_ft.webp";
// Stylesheet
import "./landmarkgoa.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkgoa' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>GOAN AESTHETIC MEETS
                        GLOBAL DESIGN</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                    <p>Goa, with its golden beaches and swaying palm trees exudes a natural charm that’s both captivating and serene. The rustic allure of centuries-old Portuguese architecture, nestled among vibrant green landscapes, tells tales of a bygone era. The rolling waves and the gentle rustling of coconut fronds create a coastal symphony enjoyed by few. The community and comradarie of a laid back lifestyle visible in everybody that passes by.
                        It is that Goan paradise that underscores the design of 101 Residences. Spacious 2 and 3-bedroom residences, with highlights of the iconic coastal style, complete with an array of lifestyle amenities.</p>
                        <p>101 Residences is the Goa you never want to leave behind, with the modern comforts you’ve grown accustomed to.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
