import React from 'react';
import Switch from '../../switch/Switch';
import AdminTitlePrimary from '../../adminTitle/AdminTitlePrimary';
import DargIcn from '../../../../../assets/admin/icons/drag-dark.svg';
import EditIcn from '../../../../../assets/admin/icons/edit-dark.svg';
import DeleteIcn from '../../../../../assets/admin/icons/delete-dark.svg';
import BannerIMG from '../../../../../assets/hero.png';
import './heroBannerListing.scss';

const HeroBannerListing = () => {
  const tableTitles = [' ', 'MEDIA', 'MEDIA TYPE', 'CAPTION', 'CTA', 'ACTION', 'STATUS'];
  return (
    <section className="admin_hero_banner_container">
      <AdminTitlePrimary title="Hero banner list" btnPath="/admin/home/add-banner" btnType="Add New" />
      <div className="listings">
        <table>
          <tbody>
            <tr>
              {tableTitles.map((item, index) => (
                <th key={index}>{item}</th>
              ))}
            </tr>
            <tr>
              <td>
                <img src={DargIcn} alt="drag_icon" loading="lazy" />
              </td>
              <td>
                <img className="hero_banner" src={BannerIMG} alt="hero_banner" loading="lazy" />
              </td>
              <td>
                <p>Video</p>
              </td>
              <td>
                <p>Perfection in every details</p>
              </td>
              <td>
                <p>Explore Projects</p>
              </td>
              <td>
                <img className="action_icn" src={EditIcn} alt="edit_icon" loading="lazy" />
                <img className="action_icn" src={DeleteIcn} alt="edit_icon" loading="lazy" />
              </td>
              <td>
                <Switch />
              </td>
            </tr>
            <tr>
              <td>
                <img src={DargIcn} alt="drag_icon" loading="lazy" />
              </td>
              <td>
                <img className="hero_banner" src={BannerIMG} alt="hero_banner" loading="lazy" />
              </td>
              <td>
                <p>Video</p>
              </td>
              <td>
                <p>Perfection in every details</p>
              </td>
              <td>
                <p>Explore Projects</p>
              </td>
              <td>
                <img className="action_icn" src={EditIcn} alt="edit_icon" loading="lazy" />
                <img className="action_icn" src={DeleteIcn} alt="edit_icon"  loading="lazy" />
              </td>
              <td>
                <Switch />
              </td>
            </tr>
            <tr>
              <td>
                <img src={DargIcn} alt="drag_icon" loading="lazy" />
              </td>
              <td>
                <img className="hero_banner" src={BannerIMG} alt="hero_banner" loading="lazy" />
              </td>
              <td>
                <p>Video</p>
              </td>
              <td>
                <p>Perfection in every details</p>
              </td>
              <td>
                <p>Explore Projects</p>
              </td>
              <td>
                <img className="action_icn" src={EditIcn} alt="edit_icon" loading="lazy" />
                <img className="action_icn" src={DeleteIcn} alt="edit_icon" loading="lazy" />
              </td>
              <td>
                <Switch />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default HeroBannerListing;
