import React from 'react';
import { motion } from 'framer-motion';
// Images
import GroupSlider from './GroupSlider';
import Layout from '../../../layout/LayoutMain/LayoutMain';
// Stylesheet
import './grouppage.scss';
import { groupData } from '../../../../constants/groupData';
import { textAnimate } from '../../home/projects/Projects';

const GroupPage = () => {
  return (
    <section className="grouppage" id="groupofcompany">
      <Layout>
        <div className="main_group">
          <motion.div className="title_container" initial="offscreen" whileInView="onscreen">
            <motion.h2 variants={textAnimate}>Group Of Companies</motion.h2>
          </motion.div>
          <p>
          At Dynamix Group, our reach extends far beyond real estate. We are a dynamic conglomerate of companies dedicated to shaping diverse industries with innovation, integrity, and excellence.
          </p>
        </div>

        <div className="listing_group">
          <ul>
            {groupData.map((item, index) => (
              <li key={index}>
                <a href={item.href}>
                <h4>
                  <small>{item.title}</small>{' '}
                  <span className="hiddenimg">
                    {' '}
                    <img src={item.src} alt="GroupImage" loading="lazy" />
                  </span>
                </h4>
              </a>
              </li>
            ))}
          </ul>
        </div>

        <div className="mobileslideronly">
          <GroupSlider expData={groupData} />
        </div>
      </Layout>
    </section>
  );
};

export default GroupPage;
