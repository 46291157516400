import React from 'react';
import AdminTitle from '../../adminTitle/AdminTitle';
import UploadImg from '../../../../../assets/admin/upload.svg';
import '../aboutSection/aboutSection.scss';

const VirtualTourSection = () => {
  return (
    <div className='admin_about_section'>
      <AdminTitle text="Virtual tour section" />
      <div className='about_container'>
        <div className='input_text_container'>
          <div>
            <label htmlFor="heading">Heding</label>
            <input type="text" id="heading" name="heading" />
          </div>
          <div>
            <label htmlFor="paragraph">Paragraph</label>
            <textarea type="text" id="paragraph" name="paragraph" />
          </div>
          <div>
            <label htmlFor="heading">URL</label>
            <input type="text" id="heading" name="heading" />
          </div>
        </div>
        <div className='input_file_container'>
          <input type="file" id="file" accept="image/*" />
          <label htmlFor="file" className='input_file'>
            <img src={UploadImg} alt="upload_img" loading="lazy" />
            <p className='file_title'>Upload short video</p>
            <p>This area is designated for a rapid walkthrough; you can use video captured on a mobile device.</p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default VirtualTourSection;
