import React from 'react';
// Components
import Navigation from '../../components/common/navigation/Navigation';
import Landmark from '../../components/common/education/landmark/Landmark';
import Experience from '../../components/common/education/experience/Experience';
import Facilities from '../../components/common/education/facilities/Facilities';
import Contact from '../../components/common/contact/Contact';
import Footer from '../../components/common/footer/Footer';
import { Helmet } from "react-helmet";



const EducationPage = () => {
  return (
    <>
      <Helmet>
        <title>Goenka & Associates Educational Trust - Dynamix Group</title>
        <meta name="description" content="The Goenka & Associates Educational Trust was established to give best education to every child through its schools." />
        <link rel="canonical" href="https://www.dynamixgroup.co.in/education" /> 
      </Helmet>
      <Navigation />
      <Landmark />
      <Experience />
      <Facilities />
      <Contact />
      <Footer />
    </>
  );
};

export default EducationPage;
