import React, { useState, useRef } from 'react';

// StyleSheet
import './timeline.scss';
import { timeLineData } from '../../../../constants/timelineData';

const Timeline = () => {
  const contentRef = useRef(null);
  const [isDragging, setIsDragging] = useState(true);
  const [startX, setStartX] = useState(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - scrollLeft);
  };

  const isEven = (n) => {
    return n % 2 == 0;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleOnMouseLeave = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const element = document.querySelector('.as');
    const offsetX = e.clientX - startX;
    const rect = element.getBoundingClientRect();
    const distanceToLeftBorder = rect.left;

    if (distanceToLeftBorder < 0) {
      setScrollLeft(offsetX);
    }
  };
  const handleTouchStart = (e) => {
    handleMouseDown(e.touches[0]);
    pauseAnimation();
  };

  const handleTouchEnd = () => {
    handleMouseUp();
    resumeAnimation();
  };

  const pauseAnimation = () => {
    const element = document.querySelector('.top_container_animation');
    if (element) {
      element.style.animationPlayState = 'paused';
    }
  };

  const resumeAnimation = () => {
    const element = document.querySelector('.top_container_animation');
    if (element) {
      element.style.animationPlayState = 'running';
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const element = document.querySelector('.as');
    const offsetX = e.touches[0].clientX - startX;
    const rect = element.getBoundingClientRect();
    const distanceToLeftBorder = rect.left;

    if (distanceToLeftBorder < 0) {
      setScrollLeft(offsetX);
    }
  };
  const handleOTouchLeave = () => {
    setIsDragging(true);
  };

  const repeatFactor = 10;

  return (
    <div className="top_container_s" style={{ overflow: 'hidden' }}>
      <div className="top_container_animation">
        <div
          ref={contentRef}
          className="as"
          style={{
            transform: `translateX(${scrollLeft}px)`
          }}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleOnMouseLeave}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onTouchCancel={handleOTouchLeave}
        >
          {Array.from({ length: timeLineData.length * repeatFactor }).map((_, index) => {
            const dataIndex = index % timeLineData.length;
            return (
              <section className="timeline" key={index}>
                <div
                  className={`timeline_item  ${
                    isEven(index) ? 'timeline_item_doted timeline_top_container' : 'timeline_item_down timeline_bottom_container'
                  } `}
                >
                  <div className="timeline_line">
                    <div className="bullet_container">
                      <div className="bullet"></div>
                    </div>
                    <div className="line"></div>
                    <div className="bullet"></div>
                  </div>
                  <div className="timeline_container">
                    {isEven(index) ? (
                      <div className="timeline_content">
                        <h3>{timeLineData[dataIndex].year}</h3>
                        <p>{timeLineData[dataIndex].para}</p>
                      </div>
                    ) : (
                      <div className="timeline_content">
                        <p>{timeLineData[dataIndex].para}</p>
                        <h3>{timeLineData[dataIndex].year}</h3>
                      </div>
                    )}

                    <div className="timeline_img">
                      <img src={timeLineData[dataIndex].src} alt="Story1" loading="lazy"/>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
          {Array.from({ length: timeLineData.length * repeatFactor }).map((_, index) => {
            const dataIndex = index % timeLineData.length;
            return (
              <section className="timeline" key={index}>
                <div
                  className={`timeline_item  ${
                    isEven(index) ? 'timeline_item_doted timeline_top_container' : 'timeline_item_down timeline_bottom_container'
                  } `}
                >
                  <div className="timeline_line">
                    <div className="bullet_container">
                      <div className="bullet"></div>
                    </div>
                    <div className="line"></div>
                    <div className="bullet"></div>
                  </div>
                  <div className="timeline_container">
                    {isEven(index) ? (
                      <div className="timeline_content">
                        <h3>{timeLineData[dataIndex].year}</h3>
                        <p>{timeLineData[dataIndex].para}</p>
                      </div>
                    ) : (
                      <div className="timeline_content">
                        <p>{timeLineData[dataIndex].para}</p>
                        <h3>{timeLineData[dataIndex].year}</h3>
                      </div>
                    )}

                    <div className="timeline_img">
                      <img src={timeLineData[dataIndex].src} alt="Story1" loading="lazy"/>
                    </div>
                  </div>
                </div>
              </section>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
