import React, { useRef } from 'react';
// Component
// Swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';

// Images
import SliderBtn from '../../../../assets/icons/cursor-arrow.svg';
import SliderBtnleft from '../../../../assets/icons/cursor-arrowleft.svg';

// SwiperCore.use([Keyboard, Mousewheel]);

const ManagemantSlider = ({ expData }) => {
  const swiperRef = useRef();
  return (
    <div className="exp_slider_container">
      <img className="slider_btn leftbtn" onClick={() => swiperRef.current.slidePrev()} src={SliderBtnleft} alt="Slider Btn" loading="lazy" />
      <img className="slider_btn" onClick={() => swiperRef.current.slideNext()} src={SliderBtn} alt="Slider Btn" loading="lazy" />
      <Swiper
        spaceBetween={0}
        slidesPerView={2}
        // mousewheel={true}
        breakpoints={{
          '@0.00': {
            slidesPerView: 2,
            spaceBetween: 14
          },
          '@0.75': {
            slidesPerView: 2,
            spaceBetween: 14
          },
          '@1.00': {
            slidesPerView: 2,
            spaceBetween: 0
          },
          '@1.50': {
            slidesPerView: 2,
            spaceBetween: 0
          }
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        modules={[Mousewheel]}
      >
        {expData?.map((item, indx) => (
          <SwiperSlide key={indx}>
            <div className="sliderbox">
              <div className="exp_item">
                <img src={item.src1} alt="slider img" />
                <div className="shadow"></div>
                <div className="slider_cntent">
                  <h3>{item.titleone}</h3>
                  <p>{item.typeone}</p>
                </div>
                <div class="overlaybg">
                  <div class="contentonhover">
                    <p>{item.frstpara1}</p>
                    <p>{item.frstpara2}</p>
                    <p>{item.frstpara3}</p>
                    <p>{item.frstpara4}</p>
                    <p>{item.frstpara5}</p>
                    <p>{item.frstpara6}</p>
                    <p>{item.frstpara7}</p>
                    <p>{item.frstpara8}</p>
                  </div>
                </div>
              </div>

              
              <div className="exp_item">
                <img src={item.src2} alt="slider img" />
                <div className="shadow"></div>
                <div className="slider_cntent">
                  <h3>{item.titletwo}</h3>
                  <p>{item.typetwo}</p>
                </div>
                <div class="overlaybg">
                <div class="contentonhover">
                    <p>{item.secondpara1}</p>
                    <p>{item.secondpara2}</p>
                    <p>{item.secondpara3}</p>
                    <p>{item.secondpara4}</p>
                    <p>{item.secondpara5}</p>
                    <p>{item.secondpara6}</p>
                    <p>{item.secondpara7}</p>
                    <p>{item.secondpara8}</p>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ManagemantSlider;
