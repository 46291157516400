import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/ADG-Overview.webp";
// Stylesheet
import "./landmarkgoa.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkgoa' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>Experience Goa The ‘Aldeia De Goa’ Way</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                    <h4>Spanning 145 acres of picturesque land, Aldeia de Goa is a haven that encapsulates the true essence of Goa. From secluded seaside spots to tranquil bays, and lush gardens to breathtaking hillside vistas, our little piece of heaven offers an enchanting retreat like no other.</h4>
                    <p>This idyllic development presents a prime seaside gated community, providing an immersive experience that is unparalleled. Designed by renowned architects, Aldeia de Goa offers fully developed plots, exclusive villas, and ready-to-move-in apartments nestled in an undisturbed bay near Bambolim Beach (North Bay).</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
