import React from 'react'
import InvestorIcon1 from '../../../../assets/icons/clock.svg'
import InvestorIcon2 from '../../../../assets/icons/download.svg'

import UnclaimedDividentPdf from '../../../../assets/investorgoanrealPDF/Unclaimed-Dividends-IEPF.pdf'
import FormIEPF1Pdf from '../../../../assets/investorgoanrealPDF/Form-IEPF-1.pdf'
import FormIEPF2Pdf from '../../../../assets/investorgoanrealPDF/Form-IEPF-2.pdf'
import FormIEPF4Pdf from '../../../../assets/investorgoanrealPDF/Form-IEPF-4.pdf'
import FormIEPF6201819 from '../../../../assets/investorgoanrealPDF/Form-IEPF-6-2018-19.pdf'
import FormIEPF7Pdf from '../../../../assets/investorgoanrealPDF/Form-IEPF-7.pdf'

import EGMNotice27062019 from '../../../../assets/investornoticesandcircularsPDF/EGM-Notice-27.06.2019.pdf'
import Notice28032019 from '../../../../assets/investornoticesandcircularsPDF/Notice-28.03.2019.pdf'
import Noticeofagm from '../../../../assets/investornoticesandcircularsPDF/Notice-of-agm.pdf'
import MOAAOAGREC from '../../../../assets/investornoticesandcircularsPDF/MOA-AOA-GREC.pdf'
import NoticeAGMGREC2022 from '../../../../assets/investornoticesandcircularsPDF/Notice-AGM-GREC-2022.pdf'
import AddendumtoAGMNotice from '../../../../assets/investornoticesandcircularsPDF/Addendum-to-AGM-Notice.pdf'
import EGMNotice2024 from '../../../../assets/investornoticesandcircularsPDF/EGMNotice2024.pdf'
import noticeofagm2024 from '../../../../assets/investornoticesandcircularsPDF/noticeofagm2024.pdf'


import GRECAnnualReport2018 from '../../../../assets/investorannualfilingPDF/GREC-Annual-Report-2018.pdf'
import GRECFormMGT72018 from '../../../../assets/investorannualfilingPDF/GREC-Form-MGT-7-2018.pdf'
import ShareholdersDetailsGREC from '../../../../assets/investorannualfilingPDF/Shareholders-Details-GREC.pdf'
import annualreport2023 from '../../../../assets/investorannualfilingPDF/annual-report2023.pdf'
import annualreport2024 from '../../../../assets/investorannualfilingPDF/annualreport2024.pdf'
import AnnualReport2022 from '../../../../assets/investorannualfilingPDF/GREC-Annual-Report-2022.pdf'


import Layout from "../../../layout/LayoutMain/LayoutMain";
import './resourcescards.scss';

const ResourcesCards = () => {
  return (

    <section className='investorcards'>

      <div className="invest-heading">
        <div className="text-centercontent">
          <div className="main-heading">
            <div className="psudo-box">
              <h6>INVESTORS</h6>
              <span className="psudo-border"></span>
            </div>
            <h1 className='new-htwo-hone'>AN INVESTOR’S HAVEN</h1>
          </div>
          <div className="para-detail mt-4">
            <p>Unlocking Opportunities, Building Futures: Our Commitment to Investors</p>
          </div>
        </div>
      </div>

      <Layout>
        <div className="investor-cards">

          <div className='cards-row'>
            <div className="left-heading">
              <h4>Goan Real Estate and Construction Private Limited</h4>
            </div>
            {/* <div className="right-select">
              <div class="option">
                <select><option>Recent uploads</option><option>Delhi</option><option>Kolkata</option></select>
              </div>
            </div> */}
          </div>

          <div className='cards-listing'>
            <ul>
              <li><a href={UnclaimedDividentPdf} target="_blank">
                <h6><span className='inline-alter' data-replace="Unclaimed dividends (IEPF)"><small>Unclaimed dividends (IEPF)</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={FormIEPF1Pdf} target="_blank">
                <h6><span className='inline-alter' data-replace="Form IEPF-1"><small>Form IEPF-1</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={FormIEPF2Pdf} target="_blank">
                <h6><span className='inline-alter' data-replace="Form IEPF-2"><small>Form IEPF-2</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={FormIEPF4Pdf} target="_blank">
                <h6><span className='inline-alter' data-replace="Form IEPF-4"><small>Form IEPF-4</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={FormIEPF6201819} target="_blank">
                <h6><span className='inline-alter' data-replace="Form IEPF 6 2018-19"><small>Form IEPF 6 2018-19</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={FormIEPF7Pdf} target="_blank">
                <h6><span className='inline-alter' data-replace="Form IEPF-7"><small>Form IEPF-7</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
            </ul>
          </div>


        </div>

        <div className="investor-cards"  id="Annualfiling">

          <div className='cards-row'>
            <div className="left-heading">
              <h4>Annual Filing</h4>
            </div>
          
          </div>

          <div className='cards-listing'>
            <ul>
            <li><a href={annualreport2024}>
                <h6><span className='inline-alter' data-replace="Annual Report, 2024"><small>Annual Report, 2024</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>7th September 2024<small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={annualreport2023}>
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2023"><small>Goan Real Estate Company Annual Report 2023</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={AnnualReport2022}>
                <h6><span className='inline-alter' data-replace="GREC Annual Report 2022GREC Annual Report 2023"><small>Goan Real Estate Company Annual Report 2022</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href="#">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>32nd Goan Real Estate Company Annual Report 2021</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href="#">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Goan Real Estate Company Annual Report 2020</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href="#">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Goan Real Estate Company Annual Report 2019</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={GRECAnnualReport2018} target="_blank">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Goan Real Estate Company Annual Report 2018</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={GRECFormMGT72018} target="_blank">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Goan Real Estate Company Form MGT 7 2018</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={ShareholdersDetailsGREC} target="_blank">
                <h6><span className='inline-alter' data-replace="Shareholders Details Goan Real Estate Company"><small>Shareholders Details Goan Real Estate Company</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
            </ul>
          </div>


        </div>
        <div className="investor-cards"  id="Noticescirculars">

          <div className='cards-row'>
            <div className="left-heading">
              <h4>Notices & Circulars</h4>
            </div>
            {/* <div className="right-select">
              <div class="option">
                <select><option>Recent uploads</option><option>Delhi</option><option>Kolkata</option></select>
              </div>
            </div> */}
          </div>

          <div className='cards-listing'>
            <ul>
            <li><a href={noticeofagm2024}>
                <h6><span className='inline-alter' data-replace="Notice of AGM 2024"><small>Notice of AGM 2024</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>7th September 2024<small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={EGMNotice2024}>
                <h6><span className='inline-alter' data-replace="Notice Of Extra-Ordinary General Meeting 03.06.2024"><small>Notice Of Extra-Ordinary General Meeting 03.06.2024</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>03 Jun 2024 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={Noticeofagm}>
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Notice of Annual General Meeting 2023</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={MOAAOAGREC}>
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>MOA & AOA</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={NoticeAGMGREC2022}>
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Notice of Annual General Meeting</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={AddendumtoAGMNotice}>
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Addendum to AGM Notice</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href="#">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Notice GREC 2021</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href="#">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Notice GREC AGM 31.12.2020</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href="#">
                <h6><span className='inline-alter' data-replace="Goan Real Estate Company Annual Report 2022Goan Real Estate Company Annual Report 2023"><small>Notice AGM GREC 28.09.2019</small></span></h6>
                <span className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={EGMNotice27062019} target="_blank">
                <h6><span className='inline-alter' data-replace="EGM Notice 27.06.2019"><small>EGM Notice 27.06.2019</small></span></h6>
                <span  className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
              <li><a href={Notice28032019} target="_blank">
                <h6><span className='inline-alter' data-replace="Notice 28.03.2019"><small>Notice 28.03.2019</small></span></h6>
                <span  className='dte-mnth'><img src={InvestorIcon1} alt="clock" loading="lazy"/>06 Jun 2022 <small>|</small> Size: 254kb</span>
                <label><img src={InvestorIcon2} alt="clock" loading="lazy"/></label></a>
              </li>
            </ul>
          </div>


        </div>
      </Layout>

    </section>





  )
}

export default ResourcesCards
