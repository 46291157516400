import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import Divumpdf1 from '../../../../assets/resource-pdf/divum/amended_EC_divum.pdf';
import Divumpdf2 from '../../../../assets/resource-pdf/divum/compaliance_divum.pdf';
import QRdivum from '../../../../assets/pdp/divumqr/Divum-RERA-QR-Code.jpg';


// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
       <div className='pdp_decription'>
                <div className='bannertagline'>
                    <p>MahaRERA Registration Numbers P51800018784. <br/> Project details are available at <a href="https://maharera.mahaonline.gov.in" target="_blank" rel="noopener"> https://maharera.mahaonline.gov.in </a> <br/>
                    Project is mortgaged to IDBI Trusteeship Services Private Limited
                    </p>
                </div>

                <div className='pdp_barcode'>
                    <div className='barcode'>
                        <img src={QRdivum} alt="plan" loading="lazy"/>
                        {/* <span>Avanya Tower A</span> */}
                    </div>
                    {/* <div className='barcode'>
                        <img src={QRcode} alt="plan" />
                        <span>Avanya Tower B</span>
                    </div> */}
                </div>
            </div>
    </Layout>

    <Layout>
        <div className="btn-viewcenter">
          <a className="btn btn-black" target="_blank" href={Divumpdf1}>Environmental Clearance NOC</a>
          <a className="btn btn-black" target="_blank" href={Divumpdf2}>Compliance Reports</a>
        </div>
      </Layout>

    </section>

  );
};

export default Barcode;
