import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Astrum-Overview.webp";
// Stylesheet
import "./landmarkastrum.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkastrum' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>The Star That Shines Brightest On Earth Is Home</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                    <h4>Rising elegantly above the city skyline, Astrum is a remarkable 22-storey tower that offers captivating views of the bustling cityscape on one side and the verdant hues of Aarey on the other. Located in the heart of Malad, this exquisite tower finds its place near entertainment centres and thriving business districts. With its distinctive design, Astrum is set to become a prominent landmark in its own right.</h4>
                    <p>Designed to provide the utmost comfort and luxury for every member of the family, Astrum boasts an array of amenities that cater to your every need. From meticulously crafted interiors to a wealth of facilities, every detail has been thoughtfully considered to surpass your expectations.</p>
                    <p>Experience unparalleled luxury and create unforgettable moments at Astrum, where your dream of a perfect home comes to life.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
