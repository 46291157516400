import React, { useRef, useEffect } from 'react';
// Components
import Layout from "../../../layout/Layout/Layout";
import LayoutMain from "../../../layout/LayoutMain/LayoutMain";
// Images
import AG1rep from "../../../../assets/gallery/AG1rep.webp";
import AG2rep from "../../../../assets/gallery/AG2rep.webp";
import AG3rep from "../../../../assets/gallery/AG3rep.webp";
import AG4rep from "../../../../assets/gallery/AG4rep.webp";
import AG5rep from "../../../../assets/gallery/AG5rep.webp";
import AG9rep from "../../../../assets/gallery/AG9rep.webp";
import AG7rep from "../../../../assets/gallery/AG7rep.webp";
import AG8rep from "../../../../assets/gallery/AG8rep.webp";
// import AG9rep from "../../../../assets/gallery/AG9rep.webp";
// import AG10rep from "../../../../assets/gallery/AG10rep.webp";
// import AG11rep from "../../../../assets/gallery/AG11rep.webp";
// import AG12rep from "../../../../assets/gallery/AG12rep.webp";
// import AG13rep from "../../../../assets/gallery/AG13rep.webp";




// Stylesheet
import "./galleryavanya.scss";


import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

function Fancybox(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const delegate = props.delegate || "[data-fancybox]";
    const options = props.options || {};

    NativeFancybox.bind(container, delegate, options);

    return () => {
      NativeFancybox.unbind(container);
      NativeFancybox.close();
    };
  });

  return <div ref={containerRef}>{props.children}</div>;
}





const Collage = () => {
  return (
    <section className="gallerycollapseavanya">
            <Fancybox
        options={{
          Carousel: {
            infinite: false,
          },
        }}
      >

    <>
      <div className="gallary_containeravanya">
        <div className="gallary_item grid_row_span_2">
        <a data-fancybox="gallery" href={AG1rep}>
            <img src={AG1rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">ELEVATION LOW ANGLE</span>
            </div>
            </a>
        </div>
        <div className="gallary_item ">
        <a data-fancybox="gallery" href={AG2rep}>
            <img src={AG2rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">ELEVATION SIDE VIEW</span>
            </div>
            </a>
        </div>
        <div className="gallary_item grid_col_span_2 grid_row_span_2">
        <a data-fancybox="gallery" href={AG3rep}>
            <img src={AG3rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">ELEVATION FRONT VIEW</span>
            </div>
            </a>
        </div>
        <div className="gallary_item">
        <a data-fancybox="gallery" href={AG4rep}>
            <img src={AG4rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">SWIMMING POOL</span>
            </div>
            </a>
        </div>
      </div>
      <div className="gallary_containeravanya">
        <div className="gallary_item grid_row_span_2">
        <a data-fancybox="gallery" href={AG5rep}>
            <img src={AG5rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">GRAND LOBBY</span>
            </div>
            </a>
        </div>
        <div className="gallary_item ">
        <a data-fancybox="gallery" href={AG9rep}>
            <img src={AG9rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">MASTER BEDROOM</span>
            </div>
            </a>
        </div>
        <div className="gallary_item grid_col_span_2 grid_row_span_2">
        <a data-fancybox="gallery" href={AG7rep}>
            <img src={AG7rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">KITCHEN</span>
            </div>
            </a>
        </div>
        <div className="gallary_item">
        <a data-fancybox="gallery" href={AG8rep}>
            <img src={AG8rep} alt="gallery img" loading="lazy"/>
            <div class="overlay">
              <span className="hover-text">LIVING ROOM</span>
            </div>
            </a>
        </div>
      </div>
    </>
    </Fancybox>
    </section>
  )
}

const Gallery = () => {
  return (
    <section className="gallery" id="Gallery">
      <LayoutMain>
        <div className="gallary_title">
          <p>GALLERY</p>
          <h2>EXPLORE  AVANYA</h2>
        </div>
      </LayoutMain>
      <Layout>
        {/* Single collage */}
        <Collage />

      </Layout>
    </section>

  );
};


export default Gallery;
