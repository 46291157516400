import React from "react";
import MenuBtn from '../../../assets/icons/menu-button.png'
import ButtonPrimary from '../button/ButtonPrimary'

const SideBar = ({ activeNav, setActiveNav }) => {
  return (
    <>
      <div className={activeNav ? "side_bar_shadow side_bar_shadow_active" : "side_bar_shadow"}
        onClick={() => setActiveNav(!activeNav)}></div>
      <section className={activeNav ? "side_bar sidebar_active" : "side_bar"}>
        <div className="side_bar_bg_blur"></div>
        <div className="side_bar_container">
          <div className="side_bar_wrapper">
            <p>RESET</p>
            <img onClick={() => setActiveNav(!activeNav)} src={MenuBtn} alt="menu_btn" loading="lazy"/>
          </div>
          <div className="option_container">
            <div className="option">
              <div><p>Select City</p></div>
              <div>
                <select>
                  <option>Mumbai</option>
                  <option>Delhi</option>
                  <option>Kalkata</option>
                </select>
              </div>
            </div>

            <div className="option">
              <div><p>Location</p></div>
              <div>
                <select>
                  <option>Andheri East</option>
                  <option>Delhi</option>
                  <option>Kalkata</option>
                </select>
              </div>
            </div>

            <div className="option">
              <div><p>Property Type</p></div>
              <div>
                <select>
                  <option>Resident</option>
                  <option>Delhi</option>
                  <option>Kalkata</option>
                </select>
              </div>
            </div>

            <div className="option">
              <div><p>BHK</p></div>
              <div>
                <select>
                  <option>4 BHK</option>
                  <option>3 BHK</option>
                  <option>2 BHK</option>
                </select>
              </div>
            </div>
          </div>
          <div className="btnbottom">          <ButtonPrimary text="Apply" />
          </div>
        </div>
      </section>
    </>
  );
};

export default SideBar;
