import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Avanya-Overview.webp";
// Stylesheet
import "./landmarkavanya.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkavanya' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>A Mumbai Address.<br/> A Global Lifestyle</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                    <h4>Avanya proudly stands tall at the gateway of the Maximum City, embracing the boundless adventure we affectionately call Mumbai. Nestled gracefully in Dahisar, this exceptional development is meticulously crafted to fulfil your vision of a truly remarkable life. It presents a captivating ecosystem of lush green landscapes seamlessly blended with thoughtful amenities and facilities. Avanya is not just a residence; it's a place where your aspirations thrive while keeping you connected to nature and your loved ones.</h4>
                    <p>This remarkable residential complex enjoys an unparalleled location. Whether you're heading to the International Airport, prominent commercial districts, major highways, or the upcoming metro station, everything is just minutes away, making your daily commute effortless. In close proximity, you'll find business parks, renowned international and ICSE schools, junior colleges, medical facilities, cinemas, malls, and places of worship. Avanya embodies the essence of connectivity, convenience, and an enriched lifestyle.</p>
                    <p>By incorporating Avanya into your life, you embrace the epitome of connectivity, convenience, and an elevated lifestyle</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
