import React, { useState, useRef } from 'react';
import Layout from '../../../layout/Layout/Layout';

const CalcContainer = () => {
  const loanSliderRef = useRef(null);
  const interestSliderRef = useRef(null);
  const tenureSliderRef = useRef(null);
  const [loanValue, setLoanValue] = useState(500000);
  const [interestValue, setInterestValue] = useState(5);
  const [tenureValue, setTenureValue] = useState(1);
  const [month, setMonth] = useState(true);

  const [amt, setAmt] = useState(502083);

  const [ir, setIr] = useState(2083);

  const [totalAmt, setTotalAmt] = useState(502083);

  const handleLoan = (e) => {
    setLoanValue(e.target.value);
  };
  const handleInterest = (e) => {
    setInterestValue(e.target.value);
  };

  const handleTenure = (e) => {
    setTenureValue(e.target.value);
  };
  const handleBtn = () => {
    setMonth(!month);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const monthlyInterestRate = interestValue / 12 / 100;
    const totalPayments = month ? tenureValue : 12 * tenureValue;
    const monthlyPayment = (loanValue * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -totalPayments));
    const totalInterest = Math.round(monthlyPayment * totalPayments - loanValue);
    const  totalLoanVal = parseInt(loanValue);
    setIr(Math.round(totalInterest));
    setAmt(Math.round(monthlyPayment));
    setTotalAmt(totalLoanVal + totalInterest);
  };
  // console.log(loanValue);

  const handleReset = () => {
    setLoanValue(500000);
    setInterestValue(5);
    setTenureValue(1);
    setMonth(true);
    setAmt(502083);
    setIr(2083);
    setTotalAmt(502083);

    loanSliderRef.current.value = 500000;
    interestSliderRef.current.value = 5;
    tenureSliderRef.current.value = 1;
  };
  return (
    <Layout>
      <div className="calc_container">
        {/* left side */}
        <div className="calc_wrapper">
          <div className="single_container">
            <h4>Please share your required loan amount</h4>
            <input className="input_text" type="text" value={loanValue} name="loanValue" onChange={(e) => setLoanValue(e.target.value)} />
            <div className="range_container">
              <input
                ref={loanSliderRef}
                className="slider"
                type="range"
                defaultValue={loanValue}
                value={loanValue}
                onChange={handleLoan}
                min={500000}
                max={100000000}
              />
            </div>
          </div>
          <div className="single_container">
            <h4>Please indicate the rate of interest</h4>
            <input type="text" value={interestValue} />
            <div className="range_container">
              <input ref={interestSliderRef}  type="range" className="slider" defaultValue={interestValue} onChange={handleInterest} min={5} max={30} />
            </div>
          </div>
          <div className="single_container">
            <h4>Please state the tenure of the loan</h4>
            <div className="btn_group">
              <input type="text" value={tenureValue} />
              <button onClick={handleBtn} className={month ? 'activeTime' : ''}>
                Mo
              </button>
              <button onClick={handleBtn} className={month ? '' : 'activeTime'}>
                Yrs.
              </button>
            </div>
            <div className="range_container">
              <input ref={tenureSliderRef} type="range" className="slider" defaultValue={tenureValue} onChange={handleTenure} min={1} max={999} />
            </div>
          </div>
          <div className="submit_btn">
            <button className="btn_primary" onClick={handleSubmit}>
              Find EMI
            </button>
            <button className="btn_primary" onClick={handleReset}>
              Reset
            </button>
          </div>
        </div>
        {/* right side */}
        <div className="tot_container">
          <div>
            <h2 className="main_price">₹{amt.toLocaleString()}</h2>
            <p>is the estimated monthly installment for your dream home.</p>
          </div>
          <div>
            <h2 className="other_price">₹{ir.toLocaleString()}</h2>
            <p>is the total interest payable over the loan term.</p>
          </div>
          <div>
            <h2 className="other_price">₹{totalAmt.toLocaleString()}</h2>
            <p>is the total monthly payment (principal amount + interest).</p>
            <p className="alart">
              *The calculations provided on this website are for reference purposes<br/> only and do not constitute a formal offer. Please seek personalised financial advice for accurate guidance.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CalcContainer;
