import React from "react";
// Components
import Layout from "../../../layout/LayoutMain/LayoutMain";
// Images
import LandmarkImg from "../../../../assets/pdp/Divum-Overview.webp";

// Stylesheet
import "./landmarkdivum.scss";

const Landmark = () => {
    return (
        <Layout>
            <section className='landmarkdivum' id="Overview">
                <div>
                    {/* <p>Overview</p> */}
                    <h2>Western Suburbs’ Newest Landmark</h2>
                    <img src={LandmarkImg} alt="landmark img" loading="lazy"/>
                </div>
                <div>
                    <h4>Goregaon, one of Mumbai's best-connected and fastest-growing suburbs, offers a unique blend of a vibrant residential zone and a world-class business district, complemented by exceptional leisure and lifestyle facilities. It's no wonder that this dynamic combination has made Goregaon one of the most sought-after suburbs in our eclectic city.</h4>
                    <p>Situated in close proximity to Goregaon East and just moments away from renowned establishments such as the Westin Mumbai Garden City, Nirlon Knowledge Park, International Business Park, Gokuldham Complex, and esteemed educational institutions like Gokuldham, Yashodham, and Lakshdham High Schools, Divum stands as a serene haven of convenience for both, couples and families. Additionally, its proximity to Oberoi Mall, Oberoi International School, and Film City add to its allure.</p>
                    <p>With Divum, you'll find a perfect blend of tranquillity and accessibility, creating an ideal living environment that caters to your every need.</p>
                </div>
            </section>
        </Layout>
    )
}

export default Landmark;
