import React from 'react';
// import { motion } from 'framer-motion';
// Video
// import { Link } from 'react-router-dom';
import Layout from "../../../layout/Layout/Layout";


import raetqr from '../../../../assets/pdp/algoaqr/raetqr.jpg';

// Stylesheet
import './barcode.scss';



const Barcode = () => {
  return (

    <section className='barcode-sec'>   
       <Layout>
       <div className='pdp_decription'>
                <div className='bannertagline'>
                    <p>GoaRERA Registration Numbers - PRGO07242259 <br/>
                    Project details are available at <a href=" https://rera.goa.gov.in/" target="_blank" rel="noopener">  https://rera.goa.gov.in/</a> under registered projects</p>
                </div>

                <div className='pdp_barcode'>
                    <div className='barcode'>
                        <img src={raetqr} alt="plan" loading="lazy"/>
                        <span>Raet</span>
                    </div>
                </div>
            </div>
    </Layout>


    </section>

  );
};

export default Barcode;
